'use client'

import React, { useMemo } from 'react'
import { ErrorMessage } from '@hookform/error-message'
import parsePhoneNumber, { CountryCode } from 'libphonenumber-js'
import { Controller, FieldValues, RegisterOptions } from 'react-hook-form'
import Select from 'react-select'

import { ButtonV2 } from '@fsg/gui-bits'
import { XCircle } from '@fsg/icons'
import { cn } from '@fsg/utils'

import { PreferredIndexButton } from '@app/components/Form/PreferredIndexButton'
import { PhoneButton } from '@app/components/PhoneButton'
import { VALIDATIONS } from '@app/constants'
import { AccountResponse, ActivityResponse, ContactResponse, CountryResponseType, LeadResponse, OpportunityResponse, SiteResponse } from '@app/types'
import { formatCallingCodeOption, getSelectStyles, renderErrorMessage } from '@app/utils'

import { useFormContext } from './Form'
import { FormItem } from './FormItem'

const classes = {
  base: 'text-sm font-regular text-true-black',
  editMode: 'bg-white border rounded-xs border-gray py-[10px] px-[12px]',
}

const displayClassName = cn(classes.base)
const getInputClassName = (isEditing: boolean) => cn(classes.base, { [classes.editMode]: isEditing })

interface Props {
  name: string
  phoneInputProps?: PhoneInputProps
  removePhone: (index: number) => void
  updateState: React.Dispatch<React.SetStateAction<ActivityResponse[]>>
  phoneIndex: number
  entity: AccountResponse | LeadResponse | OpportunityResponse | ContactResponse | SiteResponse
  opportunity?: OpportunityResponse
  group?: string
  disablePrimary?: boolean
  placeholder?: string
  numberRequired?: number
  countries: CountryResponseType
}

export function PhoneNumberField(props: Props) {
  const {
    isEditing,
    formState: { errors },
    watch,
    clearErrors,
  } = useFormContext()

  const {
    entity,
    updateState,
    opportunity,
    placeholder = 'No phone number provided',
    disablePrimary,
    removePhone,
    phoneIndex,
    phoneInputProps,
    countries,
    // numberRequired,
  } = props

  const phoneNumberValue = watch(`${props.name}.number`)

  const currentCallingCode: ReturnType<typeof formatCallingCodeOption> = watch(`${props.name}.callingCode`)
  const currentPhoneNumber: string = watch(`${props.name}.number`)
  const currentExtension: string = watch(`${props.name}.extension`)

  function handleFocus() {
    clearErrors(`${props.group}.arrayError`)
  }

  const onClickRemove = () => removePhone(phoneIndex)

  const label = disablePrimary ? (
    'Phones'
  ) : (
    <div className="flex items-center justify-between  gap-3xs text-sm text-gray-darker">
      <div className="flex  gap-3xs">
        {props.numberRequired > 0 && isEditing ? VALIDATIONS.styleLabelString('Phone') : 'Phone'}
        <PreferredIndexButton group={props.group} index={phoneIndex} fieldName="setPrimaryPhone" />
      </div>
      <div>
        <RemoveButton onClick={onClickRemove} />
      </div>
    </div>
  )

  const icon = (
    <PhoneButton
      updateState={updateState}
      opportunity={opportunity ?? null}
      entityId={entity.id}
      entity={entity}
      className="text-sm float-right"
      iconClass="bg-transparent h-auto w-auto"
      phoneNumber={`+${currentCallingCode?.value.telephoneCode}${phoneNumberValue}`}
    />
  )

  return (
    <FormItem border={false} label={label} icon={icon}>
      {isEditing ? (
        <div className="flex w-full flex-col gap-xs">
          <div className="grid w-full grid-cols-[2fr_3fr_1fr] gap-xs">
            <CallingCodeSelect name={`${props.name}.callingCode`} handleFocus={handleFocus} countries={countries} />
            <PhoneInput name={`${props.name}.number`} handleFocus={handleFocus} rules={phoneInputProps?.rules} />
            <ExtensionInput name={`${props.name}.extension`} handleFocus={handleFocus} />
          </div>
          <ErrorMessage errors={errors} name={`${props.name}.number`} render={renderErrorMessage} />
        </div>
      ) : (
        <Display phoneNumber={currentPhoneNumber} callingCode={currentCallingCode} extension={currentExtension} placeholder={placeholder} />
      )}
    </FormItem>
  )
}

type CallingCodeSelectProps = {
  name: string
  handleFocus: () => void
  countries: CountryResponseType
}
function CallingCodeSelect(props: CallingCodeSelectProps) {
  const { control } = useFormContext()
  const { name, handleFocus, countries } = props

  const callingCodeOptionsMap = useMemo(() => {
    return new Map(countries.map((country) => [country.alpha2code, formatCallingCodeOption(country)]))
  }, [countries])

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Select
          onFocus={() => handleFocus()}
          options={Array.from(callingCodeOptionsMap.values())}
          isClearable={false}
          {...field}
          {...getSelectStyles()}
        />
      )}
    />
  )
}

interface PhoneInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  rules: Omit<RegisterOptions<FieldValues, `${string}.number`>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>
  handleFocus: () => void
}

function PhoneInput({ name, rules, handleFocus, placeholder, ...inputProps }: PhoneInputProps) {
  const { control, isEditing } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        ...rules,
        pattern: VALIDATIONS.PHONE_PATTERN,
        validate: (value: any) => {
          if (!value || !value.trim()) {
            return 'Number is required'
          }
          return true
        },
      }}
      render={({ field }) => (
        <input
          {...field}
          {...inputProps}
          onFocus={() => handleFocus()}
          className={getInputClassName(isEditing)}
          placeholder="Phone number"
          type="tel"
        />
      )}
    />
  )
}

type ExtensionInputProps = {
  name: string
  handleFocus: () => void
}

function ExtensionInput(props: ExtensionInputProps) {
  const { control, isEditing } = useFormContext()
  const { name, handleFocus } = props

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <input {...field} onFocus={() => handleFocus()} className={getInputClassName(isEditing)} placeholder="Ext" type="text" />
      )}
    />
  )
}

type RemoveButtonProps = {
  onClick: () => void
}
function RemoveButton({ onClick }: RemoveButtonProps) {
  const { isEditing } = useFormContext()

  return isEditing ? (
    <ButtonV2 type="button" onClick={onClick} iconOnly variant="error" size="lg">
      <XCircle />
    </ButtonV2>
  ) : null
}

type DisplayProps = {
  phoneNumber: string
  callingCode: ReturnType<typeof formatCallingCodeOption>
  extension: string
  placeholder: string
}
function Display(props: DisplayProps) {
  const { phoneNumber, callingCode, extension, placeholder } = props
  try {
    let content
    // If no phone number provided, display the placeholder
    if (!phoneNumber) {
      content = placeholder
    }

    const phone = parsePhoneNumber(phoneNumber, callingCode.value.alpha2code as CountryCode)

    // If phone number cannot be parsed, throw error, which will go to catch block and return an error UI
    if (!phone) throw new Error('Error parsing number')

    // Otherwise, format the phone data for display
    const _extension = extension ? `ext.${extension}` : null

    content = (
      <>
        +{callingCode.value.telephoneCode} {phone.format('NATIONAL')} <span className="text-gray-700">{_extension}</span>
      </>
    )

    return <span className={displayClassName}>{content}</span>
  } catch (e) {
    return <span className="text-error">Error</span>
  }
}
