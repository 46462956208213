'use client'

import React, { PropsWithChildren, useRef, useState } from 'react'
import { datadogRum } from '@datadog/browser-rum'
// ! WARNING: Barrel file export @app/api will cause compile errors due to importing server components (barrel file exports request.ts which calls server-side session), so import these two files directly
import { ContactsQuery } from 'src/lib/api/EntityQuery'
import { FilterQueryParams } from 'src/lib/api/FilterQueryParams'

import { NotificationProvider } from '@fsg/gui-bits'
import { Session, Status } from '@fsg/next-auth/types'
import { useRequestOnMount } from '@fsg/next-auth/useRequest'

import { ENDPOINTS } from '@app/constants'
import { APIResponse, ContactResponse, CountryResponseType, CreationSuccess, Division, StateResponse } from '@app/types'
import { FormRegistry } from '@app/utils/formRegistry'

import { NewLeadFormData } from '../Create/Lead'

type RequestState<T> = { data: T[]; status: Status; error: Error | null }

type AppContextState = {
  countries: CountryResponseType
  divisions: RequestState<Division>
  session: Session
  userContact: ContactResponse
  userContactSearch: ReturnType<typeof useRequestOnMount<APIResponse<ContactResponse>>>
  showCreateLead: boolean
  setShowCreateLead: React.Dispatch<React.SetStateAction<boolean>>
  showCreateSite: boolean
  setShowCreateSite: React.Dispatch<React.SetStateAction<boolean>>
  leadData: NewLeadFormData
  setLeadData: React.Dispatch<React.SetStateAction<NewLeadFormData>>
  isDataReady: boolean
  isDiv99: boolean
  isCoolKids: boolean
  formRegistry: FormRegistry
  onCreateFunctions: CreationSuccess
  setOnCreateFunctions: React.Dispatch<React.SetStateAction<CreationSuccess>>
  states: StateResponse[]
  provinces: StateResponse[]
}
interface ProviderProps extends PropsWithChildren {
  session: Session
}

const AppContext = React.createContext<AppContextState>(undefined)
const { Provider } = AppContext

if (process.env.ENVIRONMENT !== 'IS_DEV') {
  console.debug('====================\n>> datadogRum.init()\n====================')
  datadogRum.init({
    applicationId: '6e7049de-efdf-40c2-b68b-7f44e609451d',
    clientToken: 'pubb084dac4c3d1d4bed5b1119ce6998e2c',
    site: 'us5.datadoghq.com',
    service: 'engage',
    env: 'dev',
    version: '0.1.0', // * Specify a version number to identify the deployed version of your application in Datadog
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  })
  datadogRum.startSessionReplayRecording()
}

function buildUserContactQuery(session: Session) {
  const contactQuery = new ContactsQuery()
  const q = new FilterQueryParams()
  const filter = q.textFilter({ field: 'fsgEmployeeId', value: session?.user?.employeeNumber, operation: 'equals' })
  contactQuery.filter(filter)

  return contactQuery.getPath()
}

export function AppContextProvider({ children, session }: ProviderProps) {
  // This request looks for a Contact in the database that matches the logged in user, because a user is not currently a Contact entity
  const userContactSearch = useRequestOnMount<APIResponse<ContactResponse>>({
    path: buildUserContactQuery(session),
  })
  // console.log({userContactSearch, userContactStatus, userContactError})
  const { data: countries, status: countriesStatus } = useRequestOnMount<CountryResponseType>({ path: ENDPOINTS.GEO.COUNTRIES }, [])

  const divisions = useRequestOnMount<Division[]>({ path: ENDPOINTS.DIVISIONS }, [])
  const formRegistry = useRef(new FormRegistry())
  const statesData = useRequestOnMount<StateResponse[]>({ path: ENDPOINTS.GEO.STATES('US') })
  const provincesData = useRequestOnMount<StateResponse[]>({ path: ENDPOINTS.GEO.STATES('CA') })
  const { data: states } = statesData
  const { data: provinces } = provincesData

  //controlling lead creation from app context
  const [showCreateLead, setShowCreateLead] = useState<boolean>(false)
  const [showCreateSite, setShowCreateSite] = useState<boolean>(false)
  const [onCreateFunctions, setOnCreateFunctions] = useState<CreationSuccess>({})
  const [leadData, setLeadData] = useState<NewLeadFormData | null>(null)

  const userContact = userContactSearch.data?.data?.[0]

  const isDataReady = userContactSearch.status === 'success' && countriesStatus === 'success' && divisions.status === 'success'

  const isCoolKids = session?.user?.division === 99 && session?.user?.department === 95

  const isDiv99 = session?.user?.division === 99
  const value = {
    countries,
    divisions,
    session,
    userContact,
    showCreateLead: showCreateLead,
    setShowCreateLead: setShowCreateLead,
    showCreateSite: showCreateSite,
    setShowCreateSite: setShowCreateSite,
    leadData: leadData,
    setLeadData: setLeadData,
    isDataReady,
    isDiv99,
    isCoolKids,
    userContactSearch,
    formRegistry: formRegistry.current,
    onCreateFunctions,
    setOnCreateFunctions,
    states,
    provinces,
  }

  return (
    <Provider value={value}>
      <NotificationProvider>{children}</NotificationProvider>
    </Provider>
  )
}

export function useAppContext() {
  const context = React.useContext(AppContext)

  if (context === undefined) throw new Error('Cannot call useAppContext outside of AppProvider')

  return context
}

// This uses a helper function in the generic to infer the return type of the callback function automatically
export function useAppContextSelector<T extends (state: AppContextState) => any>(callback: T): ReturnType<T> {
  const context = React.useContext(AppContext)

  if (context === undefined) throw new Error('Cannot call useAppContextSelector outside of AppProvider')

  return callback(context)
}
