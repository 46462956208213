'use client'

import React, { forwardRef } from 'react'
import { ErrorMessage } from '@hookform/error-message'
import { Controller, RegisterOptions } from 'react-hook-form'
import Select, { FormatOptionLabelMeta, GroupBase, Props } from 'react-select'
import CreatableSelect from 'react-select/creatable'

import { cn } from '@fsg/utils'

import { VALIDATIONS } from '@app/constants'
import { Option } from '@app/types'
import { getSelectStyles, renderErrorMessage, usePlaceholder } from '@app/utils'

import { useFormContext } from './Form'
import { FormItem } from './FormItem'
import { ReadOnlyField } from './ReadOnlyField'

type SingleValueDisplayProps = {
  selectedOption: Option
  className?: string
  getOptionLabel?: (option: Option) => string
  formatOptionLabel?: (option: any, { context, inputValue }: FormatOptionLabelMeta<any>) => React.ReactNode
  readOnly?: boolean
}

type MultiValueDisplayProps = {
  selectedOptions: Option[]
  getOptionLabel?: (option: Option) => string
  formatOptionLabel?: (option: any, { context, inputValue }: FormatOptionLabelMeta<any>) => React.ReactNode
  readOnly?: boolean
}

type DisplayProps = {
  value: Option | Option[]
  type: 'single' | 'multi'
  displayStyle?: string
  getOptionLabel?: (option: Option) => string
  formatOptionLabel?: (option: any, { context, inputValue }: FormatOptionLabelMeta<any>) => React.ReactNode
  placeholder?: React.ReactNode
  readOnly?: boolean
}

export interface SelectFieldProps<Option, IsMulti, Group> extends Props {
  label?: React.ReactNode
  name: string
  formItemProps?: React.ComponentProps<typeof FormItem>
  isDisabled?: boolean
  autoComplete?: string
  rules?: Omit<RegisterOptions<any, string>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>
  creatable?: boolean
  disableBottomBorder?: boolean
  displayStyle?: string
  readOnly?: boolean
  renderDisplayComponent?: (props: any) => React.JSX.Element
  containerClass?: string
}

const variants = {
  container: 'w-full py-xs text-sm border-b border-b-gray-300',
  readOnlyContainer: 'w-full text-sm',
  placeholder: 'text-gray-dark',
}

const SingleValueDisplay = ({ className, selectedOption, getOptionLabel, formatOptionLabel, readOnly }: SingleValueDisplayProps) => {
  let label
  if (formatOptionLabel) {
    label = formatOptionLabel(selectedOption, undefined)
  } else if (getOptionLabel) {
    label = getOptionLabel(selectedOption)
  } else {
    label = selectedOption.label
  }

  return <div className={`${readOnly ? variants.readOnlyContainer : variants.container} ${className ? className : null}`}>{label}</div>
}

const MultiValueDisplay = ({ selectedOptions, getOptionLabel, formatOptionLabel, readOnly }: MultiValueDisplayProps) => {
  const getLabel = (selectedOption: Option) => {
    let label
    if (formatOptionLabel) {
      label = formatOptionLabel(selectedOption, undefined)
    } else if (getOptionLabel) {
      label = getOptionLabel(selectedOption)
    } else {
      label = selectedOption.label
    }
    return label
  }

  return <div className={readOnly ? variants.readOnlyContainer : variants.container}>{selectedOptions.map(getLabel).join(', ')}</div>
}

const DefaultDisplay = ({ displayStyle, value, type, placeholder, getOptionLabel, formatOptionLabel, readOnly }: DisplayProps) => {
  const defaultPlaceholder = usePlaceholder(placeholder)
  if (value === undefined || value === null || (Array.isArray(value) && !value?.length))
    return (
      <div className={cn(`${readOnly ? variants.readOnlyContainer : variants.container} ${displayStyle ? displayStyle : null}`)}>
        <span className={variants.placeholder}>{defaultPlaceholder}</span>
      </div>
    )
  // conditionals check both `type` and `value` to satisfy TypeScript rules
  // without checking `value`, TypeScript can't tell if `value` is of type Option or Option[]
  // alternate solution is type assertion in the `value` passed to selectedOption
  if (type === 'single' && !Array.isArray(value)) {
    return (
      <SingleValueDisplay
        readOnly={readOnly}
        className={displayStyle ? displayStyle : null}
        selectedOption={value}
        getOptionLabel={getOptionLabel}
        formatOptionLabel={formatOptionLabel}
      />
    )
  } else if (type === 'multi' && Array.isArray(value)) {
    return <MultiValueDisplay readOnly={readOnly} selectedOptions={value} getOptionLabel={getOptionLabel} formatOptionLabel={formatOptionLabel} />
  } else {
    console.error(value)
    return <span>Error: Invalid type or value</span>
  }
}

export const SelectField = forwardRef<any, SelectFieldProps<Option, boolean, GroupBase<Option>>>((props, ref) => {
  const {
    creatable,
    rules,
    name,
    label,
    formItemProps,
    styles,
    classNames,
    isDisabled,
    disableBottomBorder,
    displayStyle,
    readOnly = false,
    containerClass,
    ...selectProps
  } = props

  const {
    isEditing,
    control,
    formState: { errors },
  } = useFormContext()

  if (!control) {
    throw new Error('SelectField must be used within a Form component')
  }

  const formItemPropsWithOverrides = {
    label: typeof label === 'string' && rules?.required && isEditing ? VALIDATIONS.styleLabel(label) : label,
    labelOnly: true,
    border: false,
    ...formItemProps,
  }
  const SelectElement = creatable ? CreatableSelect : Select

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => {
        const display =
          // If SelectField received a render prop AND `field.value` is not empty, render the custom display component
          props.renderDisplayComponent ? (
            // &&
            // ((!Array.isArray(field.value) && field.value !== undefined && field.value !== null) ||
            //   (Array.isArray(field.value) && field.value?.length))
            props.renderDisplayComponent(field)
          ) : (
            // Otherwise, render the default display component
            <DefaultDisplay
              readOnly={readOnly}
              displayStyle={displayStyle}
              type={props.isMulti ? 'multi' : 'single'}
              value={field.value}
              getOptionLabel={selectProps.getOptionLabel}
              formatOptionLabel={selectProps.formatOptionLabel}
              placeholder={selectProps.placeholder}
            />
          )

        const selectInput = (
          <SelectElement
            isClearable
            {...field}
            {...selectProps}
            onChange={(value, _) => {
              // Note: {...field} will return an onChange prop by default, so you do not have to supply it
              // ? If you supply an onChange prop to SelectField, field.onChange will be overwritten so your onChange callback MUST RETURN THE VALUE that you want to set in the form state
              field.onChange(selectProps.onChange ? selectProps.onChange(value, _) : value)
            }}
            menuPlacement="auto"
            isDisabled={isDisabled ? isDisabled : !isEditing}
            {...getSelectStyles({ styles, classNames })}
            ref={ref}
          />
        )

        return (
          <div className={cn('flex-col', containerClass)}>
            {readOnly ? (
              <ReadOnlyField label={formItemPropsWithOverrides.label}>{display}</ReadOnlyField>
            ) : (
              <FormItem {...formItemPropsWithOverrides}>{!isDisabled && isEditing ? selectInput : display}</FormItem>
            )}
            <ErrorMessage errors={errors} name={field.name} render={renderErrorMessage} />
          </div>
        )
      }}
    />
  )
})
SelectField.displayName = 'SelectField'
