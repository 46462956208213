import { Users } from '@fsg/icons'

import { Option } from '@app/types'
import { formatContactOption } from '@app/utils/selectOptions'

import '../optionLabelStyles.scss'

type DisplayProps = {
  option: ReturnType<typeof formatContactOption>
  selected?: boolean
}

const ContactName = ({ option }: DisplayProps) => <p className={'text-sm font-semibold text-gray-900'}>{option.label}</p>

const AccountInfo = ({ option, selected }: DisplayProps) => {
  const accountInfo = []
  if (option.data?.currentRole?.accountName) {
    accountInfo.push(option.data.currentRole.accountName)
  }
  if (option.data?.primaryPhone?.number) {
    accountInfo.push(option.data?.primaryPhone.number)
  }
  return <p className={`text-xs ${selected && 'text-gray-900'}`}>{accountInfo.join(' · ')}</p>
}

const EmailAddress = ({ option, selected }: DisplayProps) => (
  <p className={`text-xs ${selected ? '' : 'text-gray-900'}`}>{option.data?.primaryEmail?.address || option.value}</p>
)

export function formatContactOptionLabel(x: any, OptionLabelMeta: any, multi: boolean = false) {
  const option = x as ReturnType<typeof formatContactOption>
  const { context, selectValue } = OptionLabelMeta || { context: 'response' }
  const selected = selectValue.find((x: Option) => x.value === option.value)
  {
    option.data?.primaryEmail?.address ? <p className="text-xs">{option.data?.primaryEmail?.address}</p> : null
  }

  if (context === 'menu') {
    return (
      <div className="flex items-center gap-xl">
        <div>
          <Users
            className={`option-icon rounded-[50%] ${
              selected ? 'bg-blue-100 text-blue-700' : 'bg-[#EDE9FE]  text-[#492285]'
            } h-[28px] w-[28px] p-[0.5rem]`}
          />
        </div>
        <div>
          <ContactName option={option} selected={selected} />
          <AccountInfo option={option} selected={selected} />
          <EmailAddress option={option} selected={selected} />
        </div>
      </div>
    )
  } else {
    return (
      option.label.trim().length > 0 && (
        <div>
          <ContactName option={option} selected={selected} />
          {!multi && <AccountInfo option={option} selected={selected} />}
        </div>
      )
    )
  }
}

export function formatContactEmailOptionLabel(x: any, OptionLabelMeta: any) {
  const option = x as ReturnType<typeof formatContactOption>
  const { context, selectValue } = OptionLabelMeta || { context: 'response' }
  const selected = selectValue.find((x: Option) => x.value === option.value)
  {
    option.data?.primaryEmail?.address ? <p className="text-xs">{option.data?.primaryEmail?.address}</p> : null
  }

  if (context === 'menu') {
    return (
      <div className="flex items-center gap-xl">
        <div>
          <Users
            className={`option-icon rounded-[50%] ${
              selected ? 'bg-blue-100 text-blue-700' : 'bg-[#EDE9FE]  text-[#492285]'
            } h-[28px] w-[28px] p-[0.5rem]`}
          />
        </div>
        <div>
          <ContactName option={option} selected={selected} />
          <AccountInfo option={option} selected={selected} />
          <EmailAddress option={option} selected={selected} />
        </div>
      </div>
    )
  } else {
    return (
      option.label.trim().length > 0 && (
        <div>
          <EmailAddress option={option} selected={selected} />
        </div>
      )
    )
  }
}
