'use client'

import React, { forwardRef } from 'react'
import { ErrorMessage } from '@hookform/error-message'
import { Controller, RegisterOptions } from 'react-hook-form'
import { FormatOptionLabelMeta, GroupBase, Props as SelectProps } from 'react-select'
import AsyncSelect from 'react-select/async'
import AsyncCreatableSelect, { AsyncCreatableProps } from 'react-select/async-creatable'
import { CreatableProps } from 'react-select/creatable'

import { cn } from '@fsg/utils'

import { VALIDATIONS } from '@app/constants'
import { SelectOption } from '@app/types'
import { getSelectStyles, renderErrorMessage, usePlaceholder } from '@app/utils'

import { useFormContext } from './Form'
import { FormItem } from './FormItem'
import { ReadOnlyField } from './ReadOnlyField'

type SingleValueDisplayProps = {
  selectedOption: SelectOption
  className?: string
  getOptionLabel?: (option: SelectOption) => string
  formatOptionLabel?: (option: any, { context, inputValue }: FormatOptionLabelMeta<any>) => React.ReactNode
}

type MultiValueDisplayProps = {
  selectedOptions: SelectOption[]
  getOptionLabel?: (option: SelectOption) => string
  formatOptionLabel?: (option: any, { context, inputValue }: FormatOptionLabelMeta<any>) => React.ReactNode
}

type DefaultDisplayProps = {
  value: SelectOption | SelectOption[]
  type: 'single' | 'multi'
  displayStyle?: string
  getOptionLabel?: (option: SelectOption) => string
  formatOptionLabel?: (option: any, { context, inputValue }: FormatOptionLabelMeta<any>) => React.ReactNode
  placeholder?: React.ReactNode
  readOnly?: boolean
}

type StyleConfig = {
  selectStyles: any
  selectOverrides: any
  displayStyle?: string
  prepend?: string
}

interface SelectFieldProps<Option, IsMulti extends boolean, Group extends GroupBase<Option>>
  extends SelectProps<Option, IsMulti, Group>,
    AsyncCreatableProps<Option, IsMulti, Group>,
    CreatableProps<Option, IsMulti, Group> {
  label?: React.ReactNode
  name: string
  formItemProps?: React.ComponentProps<typeof FormItem>
  styleConfig?: StyleConfig
  loadOptions: (args: any) => Promise<any[]>
  renderDisplayComponent?: (props: any) => React.JSX.Element
  rules?: Omit<RegisterOptions<any, string>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>
  defaultOptions?: boolean | any[]
  readOnly?: boolean
  formatOptionLabel?: (option: any, { context, inputValue }: FormatOptionLabelMeta<any>, multi?: boolean) => React.ReactNode
  disablePlaceholder?: boolean
  bottomBorder?: boolean
  placeholder?: string
  createable?: boolean
}

const variants = {
  container: 'w-full py-xs text-sm',
  readOnlyContainer: 'w-full text-sm',
  placeholder: 'text-gray-dark',
}

const SingleValueDisplay = ({ className, selectedOption, getOptionLabel, formatOptionLabel }: SingleValueDisplayProps) => {
  let label
  if (formatOptionLabel) {
    label = formatOptionLabel(selectedOption, undefined)
  } else if (getOptionLabel) {
    label = getOptionLabel(selectedOption)
  } else {
    label = selectedOption.label
  }

  return <div className={`${variants.container} ${className ? className : null}`}>{label}</div>
}

const MultiValueDisplay = ({ selectedOptions, getOptionLabel, formatOptionLabel }: MultiValueDisplayProps) => {
  const getLabel = (selectedOption: SelectOption) => {
    let label
    if (formatOptionLabel) {
      label = formatOptionLabel(selectedOption, undefined)
    } else if (getOptionLabel) {
      label = getOptionLabel(selectedOption)
    } else {
      label = selectedOption.label
    }
    return label
  }

  return <div className={variants.container}>{selectedOptions.map(getLabel).join(', ')}</div>
}

const DefaultDisplay = ({ displayStyle, value, type, placeholder, getOptionLabel, formatOptionLabel, readOnly = false }: DefaultDisplayProps) => {
  const defaultPlaceholder = usePlaceholder(placeholder)
  if (value === undefined || value === null || (Array.isArray(value) && !value?.length))
    return (
      <div className={`${readOnly ? variants.readOnlyContainer : variants.container} ${displayStyle ? displayStyle : null}`}>
        <span className={variants.placeholder}>{defaultPlaceholder}</span>
      </div>
    )
  // conditionals check both `type` and `value` to satisfy TypeScript rules
  // without checking `value`, TypeScript can't tell if `value` is of type Option or Option[]
  // alternate solution is type assertion in the `value` passed to selectedOption
  if (type === 'single' && !Array.isArray(value)) {
    return (
      <SingleValueDisplay
        className={displayStyle ? displayStyle : null}
        selectedOption={value}
        getOptionLabel={getOptionLabel}
        formatOptionLabel={formatOptionLabel}
      />
    )
  } else if (type === 'multi' && Array.isArray(value)) {
    return <MultiValueDisplay selectedOptions={value} getOptionLabel={getOptionLabel} formatOptionLabel={formatOptionLabel} />
  } else {
    console.error(value)
    return <span>Error: Invalid type or value</span>
  }
}

export const Typeahead = forwardRef<any, SelectFieldProps<SelectOption, boolean, GroupBase<SelectOption>>>((props, ref) => {
  const {
    name,
    label,
    styleConfig,
    formItemProps,
    loadOptions,
    styles,
    rules,
    classNames,
    defaultOptions,
    readOnly,
    disablePlaceholder,
    bottomBorder = true,
    createable = false,
    ...selectProps
  } = props

  const {
    isEditing,
    control,
    formState: { errors },
  } = useFormContext()

  if (!control) {
    throw new Error('SelectField must be used within a Form component')
  }

  const formItemPropsWithOverrides = {
    label: rules?.required && isEditing ? VALIDATIONS.styleLabel(label) : label,
    labelOnly: true,
    border: false,
    ...formItemProps,
  }

  const wrapperFormatOptionLabel = (data: any, formatOptionLabelMeta: FormatOptionLabelMeta<any>) => {
    return selectProps.formatOptionLabel(data, formatOptionLabelMeta, props.isMulti)
  }

  return (
    <Controller
      rules={rules}
      name={name}
      control={control}
      render={({ field }) => {
        const display =
          // If Typeahead received a render prop and the field is not empty, render the custom display component
          props.renderDisplayComponent &&
          ((!Array.isArray(field.value) && field.value !== undefined && field.value !== null) ||
            (Array.isArray(field.value) && field.value?.length)) ? (
            props.renderDisplayComponent(field)
          ) : (
            // Otherwise, render the default display component
            <DefaultDisplay
              readOnly={readOnly}
              displayStyle={styleConfig?.displayStyle}
              type={props.isMulti ? 'multi' : 'single'}
              value={field.value}
              getOptionLabel={selectProps.getOptionLabel}
              formatOptionLabel={selectProps.formatOptionLabel}
              placeholder={selectProps.placeholder}
            />
          )

        return (
          <div className="w-full flex-col">
            {readOnly ? (
              <ReadOnlyField label={formItemPropsWithOverrides.label}>{display}</ReadOnlyField>
            ) : (
              <FormItem {...formItemPropsWithOverrides} className={bottomBorder && cn('border-b border-gray-lightest')}>
                {!selectProps.isDisabled && isEditing ? (
                  createable ? (
                    <AsyncCreatableSelect
                      {...field}
                      {...selectProps}
                      {...getSelectStyles({ styles, classNames })}
                      isClearable={props.isClearable} // ? Should this always be true?
                      onChange={(value, _) => {
                        // Note: {...field} will return an onChange prop by default, so you do not have to supply it
                        // ? If you supply an onChange prop to SelectField, field.onChange will be overwritten so your onChange callback MUST RETURN THE VALUE that you want to set in the form state
                        selectProps.onChange ? selectProps.onChange(value, _) : field.onChange(value)
                      }}
                      formatOptionLabel={wrapperFormatOptionLabel}
                      loadOptions={loadOptions}
                      isDisabled={!isEditing}
                      placeholder={!disablePlaceholder ? (selectProps.placeholder ? selectProps.placeholder : 'Start typing to select...') : ''}
                      defaultOptions={defaultOptions}
                      ref={ref}
                    />
                  ) : (
                    <AsyncSelect
                      {...field}
                      {...selectProps}
                      {...getSelectStyles({ styles, classNames })}
                      isClearable={props.isClearable} // ? Should this always be true?
                      onChange={(value, _) => {
                        // Note: {...field} will return an onChange prop by default, so you do not have to supply it
                        // ? If you supply an onChange prop to SelectField, field.onChange will be overwritten so your onChange callback MUST RETURN THE VALUE that you want to set in the form state
                        selectProps.onChange ? selectProps.onChange(value, _) : field.onChange(value)
                      }}
                      formatOptionLabel={wrapperFormatOptionLabel}
                      loadOptions={loadOptions}
                      isDisabled={!isEditing}
                      placeholder={!disablePlaceholder ? (selectProps.placeholder ? selectProps.placeholder : 'Start typing to select...') : ''}
                      defaultOptions={defaultOptions}
                      ref={ref}
                    />
                  )
                ) : (
                  display
                )}
              </FormItem>
            )}
            <ErrorMessage errors={errors} name={field.name} render={renderErrorMessage} />
          </div>
        )
      }}
    />
  )
})
Typeahead.displayName = 'Typeahead'
